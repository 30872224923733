import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` because I've since moved to different
paradigms completely such as `}<a parentName="p" {...{
        "href": "/articles/single-state-tree/"
      }}>{`Single State Atom`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{` & `}<a parentName="p" {...{
        "href": "https://reactjs.org/"
      }}>{`React`}</a>{`. While MV`}{`*`}{` was
a dream on the backend it really broke down for me on the client.`}</p>
    <p>{`So, we just explored `}<a parentName="p" {...{
        "href": "/articles/learn-js/dom-libraries.html"
      }}>{`DOM Libraries`}</a>{` in
our last article, which do a whole world of good when it comes to abstracting
browser bugs and leveling the playing field. Certainly tools like
`}<a parentName="p" {...{
        "href": "http://jquery.com"
      }}>{`jQuery`}</a>{` are a powerful weapon in our arsenal for conquering
the JavaScript landscape. It can be so powerful, in fact, people can unknowingly
abuse it. Turning innocent interfaces into crimes against animation, and
bludgeoning the separation of concerns with a round house kick to the teeth of
maintainability. Let me show you the problem as it unfolds on so many websites
and applications today, then I will walk you through some of the solutions we
have at our disposal.`}</p>
    <h2>{`The DOM! The Trap! The Humanity!`}</h2>
    <p>{`A lot of times in our application we have the need for more interesting and
complex user interface components. Historically speaking we often called these
"progressive enhancements", which in fact they often are. Sometimes they are
less enhancements, but rather requirements for our user interface to make any
sense. When you get a powerful tool like jQuery into your hands your inclination
is to take a boring DOM structure and spice it up with a little jQuery romance.
Let me show you what I mean...`}</p>
    <h3>{`\\$.fn.abuse`}</h3>
    <p>{`jQuery has a wonderful plugin system which allows you to add methods to the
jQuery object to provide more functionality to your selected elements. The
trouble is in the lack of discipline and structure developers employ to
construct these plugins. Often times plugins will do so many things all in one
giant callback. Traditional Object Oriented programming patterns are thrown to
the dogs while developers struggle to take a document composed of HTML and make
it something incredible like users are growing to expect.`}</p>
    <p>{`Take for example this element, we really want to make it do something cool.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`some-element`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`title`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`WTHeck Man?`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h2`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`title`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`So we implement our plugin, whatever it may be.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`abuse`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Set some markup from DOM stored state`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`".title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`html`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`attr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Callback from AJAX to see if the user should receive their highfive.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`highFive`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`highfive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"<div>High five holmes!</div>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`appendTo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Check the time...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` time `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`now`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Inform the server the time which informs us to highfive or not.`}</span>{`
    $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ajax`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      url`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stupid/request"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` time`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"POST"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      dataType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`highFive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Then we select our element and instantiate our plugin. If you are wondering how
this works, \\$.fn is simply a pointer to jQuery's prototype. (If you don't know
what that means read up on prototypal inheritance.
`}<a parentName="p" {...{
        "href": "http://yehudakatz.com/2011/08/12/understanding-prototypes-in-javascript/"
      }}>{`This`}</a>{`
is a great article to start with.) And "this" is the element you are calling the
plugin on.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`".some-element"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`abuse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This approach is riddled with problems. Look at all the different types of work
we did in the context of our abuse plugin. What if we needed to change the way
all of our AJAX responses get processed? Why would the view need to know
anything about the server in the first place? Why does this plugin have the
power to reach outside of it's own element? What if the server wanted to
schedule the plugin to high five in a few seconds? Should we put all that logic
in our plugin too?`}</p>
    <p>{`As with all types of programming separating our concerns is fundamental to
maintainability. This is as true in JavaScript as it is anywhere else, imagine
if the next implementation of your web services included all of your database
queries, logging, routing, cacheing, etc in a single class. Yikes!`}</p>
    <p>{`Obviously the \\$.fn.abuse plugin could be refactored into smaller functions but
that still isn't enough. Even the jQuery UI project has tools to help write
robust and separated components beyond simple plugins... Look into the
`}<a parentName="p" {...{
        "href": "http://api.jqueryui.com/jQuery.widget/"
      }}>{`Widget Factory`}</a>{` which provides an
inheritance model and improved structure. It is a serious problem, that grows
with your project. Eventually plugins are fighting over DOM elements and sharing
state, your pre-javascript markup looks nothing like your inspect-able source.
It's a tearful road travel.`}</p>
    <p>{`This is not to say interacting with the document to create nifty new user
interface components is `}<em parentName="p">{`bad`}</em>{`, I am just trying to point out that we need more!
We need a layer to interact with our data, one that can handle validation and
syncing to the server. We need a view that is agnostic to concerns except
presentation. We need a way to keep our views and our models in sync so we can
stop the madness of trying synchronize them ourselves. I am having a difficult
time articulating how and why a project grows unmaintainable when you construct
everything using the DOM. If you've worked on a project of any scale odds are
you're already nodding your head saying, "Oh man, I know about this spaghetti
mess already. You are preaching to the choir son, how do I tame it?"`}</p>
    <h2>{`The Structure! The Convention! The Beauty!`}</h2>
    <p>{`If you haven't already please review some of the structural patterns
`}<a parentName="p" {...{
        "href": "http://st-www.cs.illinois.edu/users/smarch/st-docs/mvc.html"
      }}>{`Model-View-Controller`}</a>{`
and the work done by `}<a parentName="p" {...{
        "href": "http://addyosmani.com/blog"
      }}>{`Addy Osmani`}</a>{`. JavaScript MV`}{`*`}{`
or Model-View-? frameworks provide much needed structure and convention to web
application development.`}</p>
    <p>{`This article is not to explain MVC or other structural patterns. It's not to
condemn using jQuery or other DOM manipulation libraries either. I am simply
proposing those libraries have a purpose and we should keep them focused on
doing what they do best, DOM manipulation and browser normalization! It is
simply to spread the good news, these patterns fit in the JavaScript landscape
just like they do in C++, Java, Objective-C or any other language! All those
great patterns and principles we learned building native UIs are applicable in
building JavaScript UIs as well! We can take our abuse plugin an make some sense
of it... This example leverages `}<a parentName="p" {...{
        "href": "http://backbonejs.org"
      }}>{`Backbone.js`}</a>{`. Ultimately
we should refactor the DOM construction to a template library, but we will save
that for another article.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Create a model to store our data and application logic`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` State `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Backbone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Model`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`extend`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  defaults`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`now`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    highfive`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  url`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stupid/request"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Create a view to *declaratively* represent our state.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` SomeView `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Backbone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`View`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`extend`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`initialize`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`model`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change:highfive"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`highFive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`".title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`html`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`$el`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`attr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`highFive`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"<div>High five holmes!</div>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`appendTo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Instantiate our view.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SomeView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  el`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".some-element"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`See how we are able to take this horrible example and refactor it so we have a
separation of concerns? SomeView is now responsible for the DOM manipulation,
and State is responsible for all of our data management. There are so many wins
to this kind of approach. Keeping them in sync is a breeze, maintainability and
testability go way up because our code is separated into focused pieces. Reusing
and extending components becomes easy. When leveraging an approach like this,
constructing all of your markup in a browser becomes feasible, even sensible. We
can write and maintain complex user interfaces that provide desktop class
interaction. We can reuse code in new ways because it isn't coupled to a
particular DOM structure, we can connect our data with different views. We can
do all sorts of great things you didn't think JavaScript could!`}</p>
    <p>{`The JavaScript landscape has a robust collection of libraries for you to pick
from. Almost all of these libraries build on top of existing DOM libraries like
jQuery or work nicely with them. Nearly every one of these libraries provide
some notion of a Model and a View. Though their concepts and approaches are
different they all provide great ideas and tools towards writing more
maintainable web applications.`}</p>
    <h3>{`A Summary of Libraries`}</h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://backbonejs.org/"
        }}>{`Backbone.js`}</a>{` The most prolific MV`}{`*`}{` library for
JavaScript, lightweight and un-opinionated. Provides a great starting point
for little cost. There is a large ecosystem of frameworks built on top of
Backbone.js:`}</li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://marionettejs.com/"
        }}>{`MarionetteJS`}</a>{` A composite application library for
Backbone.js`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/chaplinjs/chaplin"
        }}>{`Chaplin`}</a>{` An application architecture
for Backbone.js`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://walmartlabs.github.com/thorax/"
        }}>{`Thorax`}</a>{` An opinionated Backbone
application framework.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://emberjs.com/"
        }}>{`Ember.js`}</a>{` An excellent full featured MVC framework for
creating ambitious web applications.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://angularjs.org/"
        }}>{`AngularJS`}</a>{` An awesome structural framework from
Google providing an end-to-end solution for building web applications.
Beautiful dependency injection and testability.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://canjs.us/"
        }}>{`CanJS`}</a>{` Similar to Backbone.js, formerly known as
JavaScriptMVC.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://knockoutjs.com/"
        }}>{`Knockout`}</a>{` An MVVM framework for JavaScript. Used
heavily by the C# community.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://spinejs.com/"
        }}>{`Spine`}</a>{` Similar to Backbone.js though leans closer
towards MVC, primarily used with CoffeeScript.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://batmanjs.org/"
        }}>{`Batman.js`}</a>{` A full stack framework from Shopify, lots
of useful convention.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://meteor.com/"
        }}>{`Meteor`}</a>{` A promising new platform to construct rich
JavaScript applications. Not exactly an MV`}{`*`}{` framework but worth a mention.`}</li>
    </ol>
    <h3>{`A Note`}</h3>
    <p>{`There are a plethora of MV`}{`*`}{` framework for you to pick from.
`}<a parentName="p" {...{
        "href": "http://todomvc.com/"
      }}>{`TodoMVC`}</a>{` is a great project to help you select the right
one.`}</p>
    <h2>{`Coming Soon`}</h2>
    <p>{`Next we will talk about template libraries, giving us the power to move all our
application logic to client, by constructing our markup in the web browser.
After MV`}{`*`}{` libraries its the next logical step, right?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      